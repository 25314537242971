<template>
  <li
    @mouseenter="visible = true"
    @mouseleave="visible = false"
    class="list-item"
    :class="{ active: active || visible }"
  >
    <MyLink
      class="item"
      name="category"
      :params="{ categorySlug: category.slug }"
      @click="inactiveCatalog"
    >
      <div class="item__wrapper">
        <div v-if="category.getImageUrl('icon', false)" class="item__image">
          <img :src="category.getImageUrl('icon')" :alt="category.getName()" />
        </div>
        <span class="item__title">{{ category.getName() }}</span>
      </div>
      <div v-if="hasChildren(category)" class="item__image-arrow">
        <img src="~/assets/icons/catalog-arrow.svg" alt="navigation arrow" />
      </div>
    </MyLink>

    <template v-if="(active || visible) && hasChildren(category)">
      <div class="item__new-list">
        <div class="item__wrap">
          <div class="item__body">
            <div class="item__chunk" v-for="(chunk, index) in getChildrenChunks(category)" :key="index">
              <div class="item__chunk-el" v-for="child in chunk" :key="child.id">
                <MyLink
                  class="item category-name"
                  name="category"
                  :params="{ categorySlug: child.slug }"
                  @click="inactiveCatalog"
                >
                  <div class="item__wrapper">
                    <div v-if="child.getImageUrl('icon', false)" class="item__image">
                      <img :src="child.getImageUrl('icon')" :alt="child.getName()" />
                    </div>
                    <span class="item__title">{{ child.getName() }}</span>
                  </div>
                </MyLink>
                <template v-if="hasChildren(child)">
                  <ul>
                    <li v-for="(subChild, subIndex) in getLimitedChildren(child)" :key="subChild.id">
                      <MyLink
                        class="item"
                        name="category"
                        :params="{ categorySlug: subChild.slug }"
                        @click="inactiveCatalog"
                      >
                        <div class="item__wrapper">
                          <div v-if="subChild.getImageUrl('icon', false)" class="item__image">
                            <img :src="subChild.getImageUrl('icon')" :alt="subChild.getName()"/>
                          </div>
                          <span class="item__title">{{ subChild.getName() }}</span>
                        </div>
                      </MyLink>
                    </li>
                  </ul>
                  <MyLink
                    v-if="hasMoreChildren(child)"
                    class="item category-name"
                    name="category"
                    :params="{ categorySlug: child.slug }"
                    @click="inactiveCatalog"
                  >
                    <div class="item__wrapper">
                      <span class="item__title">{{ _T("@more") }}</span>
                    </div>
                  </MyLink>
                </template>
              </div>
            </div>
          </div>
          <div class="item__footer-submenu">
            <MyLink
              class="item__item-wrapper"
              name="category"
              :params="{ categorySlug: category.slug }"
              @click="inactiveCatalog"
            >
              <div class="catalog__image-w">
                <img
                  class="catalog__image"
                  src="@/assets/icons/grid-add-catalog.svg"
                  :alt="_T('@Category catalog')"
                />
              </div>
              <span class="item__category-title">{{ _T('@All categories') }}</span>
            </MyLink>
          </div>
        </div>
      </div>
    </template>
  </li>
</template>

<script setup>
import { ref, watch } from 'vue';
import _ from 'lodash';
import { useCategoriesStore } from '~/store/categoriesStore';
import { useHeaderStore } from '~~/store/headerStore';
import { promotionCategoryCode } from '~/config/category.config';
import { isPromotionEnded } from '~/utils/valueMatched';

const props = defineProps({
  category: Object,
  parent: { type: String, required: false },
  active: Boolean,
});

const header = useHeaderStore();
const activeCatalog = header.activeCatalog;

const categoriesStore = useCategoriesStore();
const { getChildren: children } = storeToRefs(categoriesStore);

const visible = ref(false);

const getFilteredChildren = (category) => {
  if (promotionCategoryCode !== category.code) {
    return children.value(category);
  }

  return children
    .value(category)
    .filter((child) => !isPromotionEnded(child))
    .sort((a, b) => b.promotion.period.endAt - a.promotion.period.endAt);
};

const hasChildren = (category) => {
  return getFilteredChildren(category).length > 0;
};

const flattenNestedArray = (array) => {
  let result = [];

  const flatten = (arr) => {
    arr.forEach((item) => {
      result.push(item);
      if (Array.isArray(item.children) && item.children.length > 0) {
        flatten(item.children);
      }
    });
  };

  flatten(array);
  return result;
};

const getChildrenChunks = (category, chunkSize = 3) => {
  const allChildren = getFilteredChildren(category);
  const flatChildren = flattenNestedArray(allChildren);

  return _.chunk(flatChildren, chunkSize);
};

const getLimitedChildren = (category) => {
  const children = getFilteredChildren(category);

  return children.slice(0, 5);
};

const hasMoreChildren = (category) => {
  const children = getFilteredChildren(category);

  return children.length > 5;
};

function inactiveCatalog() {
  activeCatalog(false);
  document.body.style.overflow = 'auto';
}
</script>

<style lang="scss" scoped>
.list-item {
  &.active {
    & >.item {
      background-color: white;
    }
  }

  .item {
    @include flex-container(row, space-between, center);

    padding: 8px 16px;

    cursor: pointer;

    transition: background-color 0.1s ease-in-out;

    &.category-name {
      &:hover {
        .item__title {
          color: var(--color-primary-light);
          text-decoration: underline;
        }
      }

      .item__title {
        max-width: 252px;

        @include font(14, 20, 600);
        color: var(--color-primary-base);
        transition: color .1s ease-in-out;
      }
    }

    &:hover {
      background-color: white;
    }

    &__new-list {
      width: calc(100% - 415px);
      height: 100%;

      @include flex-container(row);

      position: absolute;
      top: 0;
      left: 415px;
      z-index: 501;
      
      background-color: var(--color-grey-light-status);
      box-shadow: 0px 4px 10px 0px #00000033;
      border-radius: 0 0 16px 0;

      .item {
        &.category-name {
          padding: 0;
        }

        &:hover {
          background-color: inherit;
        }
      }

      ul {
        li {
          .item {
            padding: 0;
          }
        }
      }
    }

    &__wrap {
      width: 100%;

      @include flex-container(column);

      position: relative;
      z-index: 501;

      padding: 16px 4px 0 0;
    }

    &__body {
      width: 100%;
      
      @include flex-container(row, flex-start);
      flex: 1;
      flex-wrap: wrap;

      overflow: auto;

      @include customScrollbar();
    }

    &__chunk {
      max-width: 252px;
      width: 100%;

      @include flex-container(column, flex-start);

      gap: 16px;
      padding: 0 16px;
    }

    &__chunk-el {
      @include flex-container(column, flex-start);

      gap: 16px;

      ul {
        @include flex-container(column, flex-start);

        gap: 8px;

        li {
          span {
            @include font(14, 20);
          }
          
          &:hover,
          &:active {
            span {
              color: var(--color-primary-light);
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__footer-submenu {
      width: 100%;

      @include flex-container(row, center);

      padding-bottom: 16px;
    }

    &__item-wrapper {
      @include flex-container(row, center, center);

      padding: 8px 16px;
      gap: 8px;
    }

    &__category-title {
      color: var(--color-primary-base);
    }

    &__wrapper {
      @include flex-container(row, flex-start, center);

      gap: 16px;
    }

    &__image {
      width: 24px;
      height: 24px;

      display: flex;
      flex-shrink: 0;
    }

    &__image-arrow {
      @include fixedHW(24px);

      @include flex-container(row, center, center);
    }

    &__title {
      max-width: 270px;
      width: 100%;

      display: inline-block;

      @include font(16, 22);
      color: var(--color-ink-base);
      letter-spacing: 0.02em;
    }

    li {
      padding: 4px 16px;

      &:hover {
        background-color: #f5f5f5;
      }

      .item__title {
        font-weight: bold;
      }
    }

    ul {
      padding-left: 20px;
    }
  }
}
</style>