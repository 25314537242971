<template>
  <div class="content__block">
    <div class="content__title-w" @click="dropDownProd">
      <h4 class="content__title">
        {{ title }}
      </h4>
      <div class="content__title-mob-arrow">
        <img
          class="content__title-mob-arrow-img"
          src="@/assets/icons/nav-arrow-down.svg"
          alt=""
          :class="{ active: isActive }"
        />
      </div>
    </div>
    <div
      class="content__block-list-w"
      :class="{ active: isActive }"
      :style="{ '--list-height': listHeight + 'px' }"
    >
      <ul ref="listOfItem" class="content__block-list" :class="{ thin: thin }">
        <li
          v-for="item in list"
          :key="item.id"
          class="content__block-list-item"
        >
          <MyLink
            v-if="item.title"
            :name="item.routeName"
            class="content__block-list-link"
          >
            {{ _T(item.title) }}
          </MyLink>

          <MyLink
            v-else
            name="category"
            :params="{
              categorySlug: item.slug,
            }"
            class="content__block-list-link"
          >
            {{ item.getName() }}
          </MyLink>
        </li>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  title: { type: String, required: true },
  list: { type: Array, required: true },
  thin: { type: Boolean, required: false },
});

const listOfItem = ref(null);

const isActive = ref(false);
const listHeight = ref(0);

const dropDownProd = () => {
  if (window.innerWidth <= 680) {
    if (listHeight.value === 0) {
      listHeight.value = listOfItem.value.scrollHeight;
    } else {
      listHeight.value = 0;
    }

    isActive.value = !isActive.value;
  }
};
</script>

<style lang="scss" scoped>
.content {
  &__block {
    @include flex-container(column, stretch);
    gap: 16px;

    @include mobile {
      border-bottom: 1px solid var(--color-primary-light);
    }
  }

  &__title-w {
    @include flex-container(row, space-between, center);
  }

  &__title {
    position: relative;
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: var(--color-primary-light);
  }

  &__title-mob-arrow {
    display: none;

    @include mobile {
      display: block;
    }
  }

  &__title-mob-arrow-img {
    transition: transform 0.5s ease;

    &.active {
      transform: rotate(180deg);
    }
  }

  &__block-list-w {
    --list-gap: 8px;
    --list-height: 0;

    max-width: 416px;
    width: 100%;

    @include flex-container(row, flex-start);
    gap: var(--list-gap);
    flex-wrap: wrap;

    overflow: hidden;

    transition: height 0.5s ease;

    @include bigMobile {
      flex-direction: column;
    }

    @include mobile {
      max-width: none;
      height: var(--list-height);
    }

    &.active {
      @include mobile {
        height: var(--list-height);
      }
    }
  }

  &__block-list {
    max-width: 487px;
    width: 100%;

    @include flex-container(row, flex-start);
    gap: 16px;
    flex-wrap: wrap;

    overflow: hidden;

    &.thin {
      //max-height: 200px;

      @include mobile {
        //max-height: none;
      }
    }

    @include bigMobile {
      max-height: none;
      max-width: none;

      flex-direction: column;
      flex-wrap: nowrap;

      padding-bottom: 16px;

      overflow: visible;
    }
  }

  &__block-list-item {
    width: 100%;
    max-width: 200px;

    @include mobile {
      max-width: none;
    }
  }

  &__block-list-link {
    width: 100%;
    max-width: 200px;

    @include font;
    color: var(--color-ink-base);

    transition: 0.2s ease-in-out;

    @include mobile {
      max-width: none;
    }

    &:hover {
      color: var(--color-primary-light);
    }
  }
}
</style>