<template>
  <button ref="button" class="button-catalog" @click.prevent="showCatalog">
    <svg class="button-catalog__icon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.83813 2.41602H1V9.25414H7.83813V2.41602Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.5838 13.1616H11.7456V19.9998H18.5838V13.1616Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.83813 13.1616H1V19.9998H7.83813V13.1616Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.1634 0.999676L10.3281 5.83496L15.1634 10.6702L19.9987 5.83496L15.1634 0.999676Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="button-catalog__title">{{ _T("@Goods catalog") }}</span>
  </button>
</template>

<script setup>
import { useHeaderStore } from "~~/store/headerStore";

const header = useHeaderStore();
const modalCatalog = header.modalCatalog;
const activeCatalog = header.activeCatalog;

const button = ref(null);

const emits = defineEmits(["buttonCatalog"]);

function showCatalog() {
  if (modalCatalog.active) {
    activeCatalog(false);
    document.body.style.overflow = "auto";
  } else {
    activeCatalog(true);
    document.body.style.overflow = "hidden";
  }
}

onMounted(() => {
  emits("buttonCatalog", button.value);
});
</script>

<style lang="scss" scoped>
.button-catalog {
  width: max-content;

  @include flex-container(row, flex-start, center);

  background-color: var(--color-primary-base);
  border-radius: 30px;

  padding: 8px 16px; 
  gap: 8px;

  &:hover,
  &:active {
    .button-catalog__icon {
      path {
        stroke: var(--color-primary-yellow);
      }
    }

    .button-catalog__title {
      color: var(--color-primary-yellow);
    }
  }

  &__icon {
    path {
      transition: stroke .1s ease-in-out;
    }
  }

  &__title {
    @include font(16, 18, 700);
    color: white;
    white-space: nowrap;

    transition: color .1s ease-in-out;
  }
}
</style>