<template>
  <component
    :is="isButton ? 'div' : undefined === href ? 'MyLink' : 'a'"
    class="menu-item"
    :href="href"
    :hash="hash"
    :name="routeName"
    @click="closeModal"
  >
    <div class="menu-item__image">
      <slot name="image" />
    </div>
    <div class="menu-item__titles">
      <slot name="title" />
    </div>
  </component>
</template>

<script setup>
import { useHeaderStore } from "~~/store/headerStore";

const header = useHeaderStore();
const activeMenu = header.activeModal;

defineProps({
  routeName: { type: String, required: false },
  isButton: { type: Boolean, required: false, default: false },
  href: { type: String, required: false },
  hash: { type: String, required: false },
});

function closeModal() {
  if (window.innerWidth > 1024) {
    activeMenu(false);
  }
}
</script>

<style lang="scss">
.menu-item {
  @include flex-container(row, flex-start, center);

  gap: 32px;
  padding-block: 16px;

  &:hover .menu-item__titles > span {
    color: var(--color-primary-base);
  }

  &__titles {
    @include flex-container(column, center, flex-start);
  }
}
</style>
