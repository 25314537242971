<template>
  <div class="footer-contacts">
    <div class="footer-contacts__title-w" @click="dropDownProd">
      <h4 class="footer-contacts__title">{{ _T("@Contacts") }}</h4>
      <div class="footer-contacts__title-mob-arrow">
        <img
          class="footer-contacts__title-mob-arrow-img"
          src="@/assets/icons/nav-arrow-down.svg"
          alt=""
          :class="{ active: isActive }"
        />
      </div>
    </div>
    <div
      class="footer-contacts__block-list-w"
      :class="{ active: isActive }"
      :style="{ '--list-height': listHeight + 'px' }"
      ref="listOfItem"
    >
      <div class="footer-contacts__content-wrp">
        <FooterContactBlock :contact="hotline" />
        <FooterContactBlock
          v-for="(contact, index) in contacts"
          :key="index"
          :contact="contact"
        />
        <div class="footer-contacts__block">
          <a class="footer-contacts__link" href="mailto:support@greenvision.com.ua">
            <svg
              class="footer-contacts__link-img"
              width="29"
              height="18"
              viewBox="0 0 29 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.9062 8.99865L29.0014 16.884V1.11328L17.9062 8.99865Z"
                fill="#602C88"
              />
              <path
                d="M11.0951 8.99775L0 1.11133V16.8831L11.0951 8.99775Z"
                fill="#602C88"
              />
              <path
                d="M16.3367 10.1133L14.8381 11.1783L14.5026 11.4174L13.9221 11.005L12.6583 10.1153L1.57031 17.9967H27.4359L16.3367 10.1133Z"
                fill="#602C88"
              />
              <path
                d="M14.4985 9.19062L16.3286 7.88245L27.4237 -0.00390625H1.57031L14.4985 9.19062Z"
                fill="#602C88"
              />
            </svg>
            <span class="footer-contacts__link-text">
              {{ _T("@Ask question") }}
            </span>
          </a>
          <p class="footer-contacts__caption">
            {{ _T("@Ask us any question you have") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import FooterContactBlock from "~/modules/footer/components/FooterContactBlock.vue";

const isActive = ref(false);
const listHeight = ref(0);
const listOfItem = ref(null);

const hotline = {
  title: "@Hotline",
  phones: ["@Call center number"],
  ringo: true,
};

const contacts = [
  {
    title: "@Call-center",
    phones: ["@Call center number"],
    schedules: ["@Mon-Fri footer", "@Sat-Sun footer"],
    ringo: true,
  },
];

const dropDownProd = () => {
  if (window.innerWidth <= 680) {
    if (listHeight.value === 0) {
      listHeight.value = listOfItem.value.scrollHeight;
    } else {
      listHeight.value = 0;
    }

    isActive.value = !isActive.value;
  }
};
</script>

<style scoped lang="scss">
.footer-contacts {
  width: 100%;
  max-width: 230px;

  @include flex-container(column, stretch);
  gap: 16px;

  @include mobile {
    max-width: none;

    border-bottom: 1px solid var(--color-primary-light);

    padding-bottom: 0;
  }

  &__block {
    @include flex-container(column, stretch);
    gap: 16px;
  }

  &__title-w {
    @include flex-container(row, space-between, center);
  }

  &__title-mob-arrow {
    display: none;

    @include mobile {
      display: block;
    }
  }

  &__title-mob-arrow-img {
    transition: transform 0.5s ease;

    &.active {
      transform: rotate(180deg);
    }
  }

  &__title {
    position: relative;
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: var(--color-primary-light);
  }

  &__caption {
    @include font(12, 16);
    color: var(--color-sky-darker);
  }

  &__block-list-w {
    --list-height: 0;

    overflow: hidden;

    transition: height 0.5s ease;

    @include mobile {
      height: var(--list-height);
    }

    &.active {
      @include mobile {
        height: var(--list-height);
      }
    }
  }

  &__content-wrp {
    @include flex-container(column, flex-start);
    gap: 16px;

    @include mobile {
      padding-bottom: 16px;
    }
  }

  &__link {
    @include flex-container(row, flex-start, center);
    gap: 12px;

    &:hover .footer-contacts__link-text {
      color: var(--color-primary-base);
    }

    &:hover .footer-contacts__link-img path {
      fill: var(--color-primary-base);
    }
  }

  &__link-img path {
    transition: fill 0.2s;
  }

  &__link-text {
    @include font(18, 24, 700);
    text-transform: uppercase;
    color: var(--color-primary-base);

    transition: color 0.2s;

    &.underline {
      text-decoration: underline;
    }
  }
}
</style>
